export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_SCAN_PACKAGE_SUCCESS":
    case "GET_SCAN_PACKAGE_ERROR":
      return {
        ...state,
        GetScanPackageListResponse: action.updatePayload,
      };
    case "ADD_SCAN_PACKAGE_SUCCESS":
    case "ADD_SCAN_PACKAGE_ERROR":
      return {
        ...state,
        AddScanPackageResponse: action.updatePayload,
      };
    case "UPDATE_SCANNED_PACKAGE_SUCCESS":
    case "UPDATE_SCANNED_PACKAGE_ERROR":
      return {
        ...state,
        UpdateScannedPackageResponse: action.updatePayload,
      };
    case "DELETE_SCANNED_PACKAGE_SUCCESS":
    case "DELETE_SCANNED_PACKAGE_ERROR":
      return {
        ...state,
        DeleteScannedPackageResponse: action.updatePayload,
      };
    case "FAKE_ACTION_SCAN_PACKAGE":
      return [];
    default:
      return state;
  }
};
