import axiosCall from "../../../configurations/network-services/axiosCall";

// --------------------------------------------------------------------------- New flow by Umangi from 21-09-2024

export const GetInboundShipmentPackages = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-packages`;
  const responseType = "GET_INBOUND_SHIPMENT_PACKAGES";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SubmitPackageLevelContentsToAmazon = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/submit-package-level-contents`;
  const responseType = "SUBMIT_PACKAGE_LEVEL_CONTENTS";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CheckPackageLevelContentsToAmazon = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/check-package-contents-feed-status`;
  const responseType = "CHECK_PACKAGE_LEVEL_CONTENTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddPackageIntoShipment = (
  batch_id,
  client_id,
  shipment_id,
  data,
  package_id
) => {
  const path = package_id
    ? `prep-batch/${batch_id}/${client_id}/${shipment_id}/${package_id}/edit-package`
    : `prep-batch/${batch_id}/${client_id}/${shipment_id}/add-packages`;
  const responseType = "ADD_PACKAGE_INTO_SHIPMENT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetShipmentContents = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-shipment-contents`;
  const responseType = "GET_SHIPMENT_CONTENTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeletePackageItem = (shipment_id, item_id) => {
  const path = `prep-batch/${shipment_id}/${item_id}/remove-package-item`;
  const responseType = "DELETE_PACKAGE_ITEM";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetPackageItemsDetails = (
  batch_id,
  client_id,
  shipment_id,
  package_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/${package_id}/get-package-items`;
  const responseType = "GET_PACKAGE_ITEMS_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetShipmentContentPerBox = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-shipment-contents-per-box`;
  const responseType = "GET_SHIPMENT_CONTENT_PER_BOX";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteInboundShipmentPackage = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/remove-package`;
  const responseType = "DELETE_INBOUND_SHIPMENT_PACKAGE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetShippingCostAndTransportDetails = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-shipping-cost-and-transport-details`;
  const responseType = "GET_SHIPPING_COST_AND_TRANSPORT_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetEstimateShippingCost = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/estimate-shipping-cost`;
  const responseType = "GET_ESTIMATE_SHIPPING_COST";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ConfirmInboundShippingCost = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/confirm-shipping-cost`;
  const responseType = "CONFIRM_INBOUND_SHIPPING_COST";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const FetchShipmentTrackingDetails = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/fetch-shipment-tracking-details`;
  const responseType = "FETCH_SHIPMENT_TRACKING_DETAILS";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GenerateShippingLabels = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/generate-shipping-labels`;
  const responseType = "GENERATE_SHIPPING_LABELS";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const ConfirmShipShipment = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/ship-shipment`;
  const responseType = "CONFIRM_SHIP_SHIPMENT";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionShipPack = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SHIP_PACK" });
};
