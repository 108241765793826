import {
  GetEstimateShippingCost,
  SubmitPackageLevelContents,
} from "./ship-pack-action";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_INBOUND_SHIPMENT_PACKAGES_SUCCESS":
    case "GET_INBOUND_SHIPMENT_PACKAGES_ERROR":
      return {
        ...state,
        GetInboundShipmentPackagesResponse: action.updatePayload,
      };
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_SUCCESS":
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_ERROR":
      return {
        ...state,
        SubmitPackageLevelContentsResponse: action.updatePayload,
      };
    case "CHECK_PACKAGE_LEVEL_CONTENTS_SUCCESS":
    case "CHECK_PACKAGE_LEVEL_CONTENTS_ERROR":
      return {
        ...state,
        CheckPackageLevelContentsResponse: action.updatePayload,
      };
    case "ADD_PACKAGE_INTO_SHIPMENT_SUCCESS":
    case "ADD_PACKAGE_INTO_SHIPMENT_ERROR":
      return {
        ...state,
        AddPackageIntoShipmentResponse: action.updatePayload,
      };
    case "DELETE_PACKAGE_ITEM_SUCCESS":
    case "DELETE_PACKAGE_ITEM_ERROR":
      return {
        ...state,
        DeletePackageItemResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_CONTENTS_SUCCESS":
    case "GET_SHIPMENT_CONTENTS_ERROR":
      return {
        ...state,
        GetShipmentContentsResponse: action.updatePayload,
      };
    case "GET_PACKAGE_ITEMS_DETAILS_SUCCESS":
    case "GET_PACKAGE_ITEMS_DETAILS_ERROR":
      return {
        ...state,
        GetPackageItemsDetailsResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_CONTENT_PER_BOX_SUCCESS":
    case "GET_SHIPMENT_CONTENT_PER_BOX_ERROR":
      return {
        ...state,
        GetShipmentContentPerBoxResponse: action.updatePayload,
      };
    case "DELETE_INBOUND_SHIPMENT_PACKAGE_SUCCESS":
    case "DELETE_INBOUND_SHIPMENT_PACKAGE_ERROR":
      return {
        ...state,
        DeleteInboundShipmentPackageResponse: action.updatePayload,
      };
    case "GET_SHIPPING_COST_AND_TRANSPORT_DETAILS_SUCCESS":
    case "GET_SHIPPING_COST_AND_TRANSPORT_DETAILS_ERROR":
      return {
        ...state,
        GetShippingCostAndTransportDetailsResponse: action.updatePayload,
      };
    case "GET_ESTIMATE_SHIPPING_COST_SUCCESS":
    case "GET_ESTIMATE_SHIPPING_COST_ERROR":
      return {
        ...state,
        GetEstimateShippingCostResponse: action.updatePayload,
      };
    case "CONFIRM_INBOUND_SHIPPING_COST_SUCCESS":
    case "CONFIRM_INBOUND_SHIPPING_COST_ERROR":
      return {
        ...state,
        ConfirmInboundShippingCostResponse: action.updatePayload,
      };
    case "FETCH_SHIPMENT_TRACKING_DETAILS_SUCCESS":
    case "FETCH_SHIPMENT_TRACKING_DETAILS_ERROR":
      return {
        ...state,
        FetchShipmentTrackingDetailsResponse: action.updatePayload,
      };
    case "GENERATE_SHIPPING_LABELS_SUCCESS":
    case "GENERATE_SHIPPING_LABELS_ERROR":
      return {
        ...state,
        GenerateShippingLabelsResponse: action.updatePayload,
      };
    case "CONFIRM_SHIP_SHIPMENT_SUCCESS":
    case "CONFIRM_SHIP_SHIPMENT_ERROR":
      return {
        ...state,
        ConfirmShipShipmentResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SHIP_PACK":
      return [];
    default:
      return state;
  }
};
