export const adminMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-home-2 fs-1" />,
    id: "dashboard",
    link: "/",
    subMenu: [],
  },
  {
    title: "Entities",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    id: "entities",
    subMenu: [
      {
        title: "Client",
        id: "client",
        link: "/entities/client",
      },
      {
        title: "Prep Staff",
        // icon: <i className="ki-outline ki-people fs-1" />,
        id: "prep-staff",
        link: "/entities/prep-staff",
      },
    ],
  },
  {
    title: "Inventory Order",
    icon: <i className="ki-outline ki-tablet-text-down fs-1" />,
    id: "inventory-order",
    link: "/inventory-order",
    subMenu: [],
  },
  {
    title: "Inventory Items",
    icon: <i className="ki-outline ki-parcel fs-1" />,
    id: "inventory-items",
    link: "/inventory-items",
    subMenu: [],
  },
  {
    title: "Scan Deliveries",
    icon: <i className="ki-outline ki-scan-barcode fs-1" />,
    id: "scan-deliveries",
    link: "/scan-deliveries",
    subMenu: [],
  },
  {
    title: "Receive Items",
    icon: <i className="bi bi-upc-scan fs-1" />,
    id: "receive-items",
    link: "/receive-items",
    subMenu: [],
  },
  {
    title: "Add Item to Location",
    icon: <i className="las la-map-marked-alt fs-1" />,
    id: "add-item-to-location",
    link: "/add-item-to-location",
    subMenu: [],
  },
  {
    title: "Location",
    icon: <i className="ki-outline ki-geolocation fs-1" />,
    id: "location",
    link: "/location",
    subMenu: [],
  },
  {
    title: "FBA Packing Summary Report",
    icon: <i className="ki-outline ki-questionnaire-tablet fs-1" />,
    id: "fba-packing-summary-report",
    link: "/fba-packing-summary-report",
    subMenu: [],
  },
  {
    title: "Sources",
    icon: <i className="ki-outline ki-shop fs-1" />,
    id: "sources",
    link: "/sources",
    subMenu: [],
  },
  {
    title: "Operation",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    id: "operation",
    subMenu: [
      {
        title: "Prep Batch",
        id: "prep-batch",
        link: "/operation/prep-batch",
      },
      {
        title: "Prep Center",
        id: "prep-center",
        link: "/operation/prep-center",
      },
      {
        title: "Shipping",
        id: "shipping",
        link: "/operation/shipping",
      },
    ],
  },
  {
    title: "Admin Actions",
    icon: <i className="ki-outline ki-user-square fs-1" />,
    id: "admin-actions",
    subMenu: [
      {
        title: "Pre Receiving Listings",
        id: "pre-receiving-listings",
        link: "/admin-actions/pre-receiving-listings",
      },
      {
        title: "Cron Manager",
        id: "cron-manager",
        link: "/admin-actions/cron-manager",
      },
    ],
  },
  // {
  //     title: "Prep Staff",
  //     icon: <i className="ki-outline ki-people fs-1" />,
  //     id: 'prep-staff',
  //     link: '/prep-staff',
  //     subMenu: []
  // },
  {
    title: "Settings",
    icon: <i className="ki-outline ki-gear  fs-1" />,
    id: "settings",
    subMenu: [
      {
        title: "My Profile",
        id: "profile",
        link: "/settings/profile",
      },
      {
        title: "Billing",
        id: "billing",
        link: "/settings/billing",
      },
      {
        title: "Marketplace Credentials",
        id: "marketplace-credentials",
        link: "/settings/marketplace-credentials",
      },
      {
        title: "Help",
        id: "help",
        link: "/settings/help",
      },
    ],
  },
  {
    title: "Application Logs",
    icon: <i className="ki-outline ki-chart-simple-2 fs-1" />,
    id: "application-logs",
    subMenu: [
      {
        title: "Central Log",
        id: "central-log",
        link: "/application-logs/central-log",
      },
      {
        title: "System Error Log",
        id: "system-error-log",
        link: "/application-logs/system-error-log",
      },
    ],
  },
];

export const staffMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-home-2 fs-1" />,
    id: "dashboard",
    link: "/",
    subMenu: [],
  },
  {
    title: "Entities",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    id: "entities",
    subMenu: [
      {
        title: "Client",
        id: "client",
        link: "/entities/client",
      },
    ],
  },
  {
    title: "Inventory Order",
    icon: <i className="ki-outline ki-tablet-text-down fs-1" />,
    id: "inventory-order",
    link: "/inventory-order",
    subMenu: [],
  },
  {
    title: "Inventory Items",
    icon: <i className="ki-outline ki-parcel fs-1" />,
    id: "inventory-items",
    link: "/inventory-items",
    subMenu: [],
  },
  {
    title: "Scan Deliveries",
    icon: <i className="ki-outline ki-scan-barcode fs-1" />,
    id: "scan-deliveries",
    link: "/scan-deliveries",
    subMenu: [],
  },
  {
    title: "Receive Items",
    icon: <i className="bi bi-upc-scan fs-1" />,
    id: "receive-items",
    link: "/receive-items",
    subMenu: [],
  },
  {
    title: "Add Item to Location",
    icon: <i className="ki-outline ki-focus fs-1" />,
    id: "add-item-to-location",
    link: "/add-item-to-location",
    subMenu: [],
  },
  {
    title: "FBA Packing Summary Report",
    icon: <i className="ki-outline ki-questionnaire-tablet fs-1" />,
    id: "fba-packing-summary-report",
    link: "/fba-packing-summary-report",
    subMenu: [],
  },
  {
    title: "Sources",
    icon: <i className="ki-outline ki-shop fs-1" />,
    id: "sources",
    link: "/sources",
    subMenu: [],
  },
  {
    title: "Operation",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    id: "operation",
    subMenu: [
      {
        title: "Prep Batch",
        id: "prep-batch",
        link: "/operation/prep-batch",
      },
      {
        title: "Prep Center",
        id: "prep-center",
        link: "/operation/prep-center",
      },
      {
        title: "Shipping",
        id: "shipping",
        link: "/operation/shipping",
      },
    ],
  },
  {
    title: "Admin Actions",
    icon: <i className="ki-outline ki-user-square fs-1" />,
    id: "admin-actions",
    subMenu: [
      {
        title: "Pre Receiving Listings",
        id: "pre-receiving-listings",
        link: "/admin-actions/pre-receiving-listings",
      },
    ],
  },
  {
    title: "Settings",
    icon: <i className="ki-outline ki-gear  fs-1" />,
    id: "settings",
    subMenu: [
      {
        title: "My Profile",
        id: "profile",
        link: "/settings/profile",
      },
      {
        title: "Billing",
        id: "billing",
        link: "/settings/billing",
      },
      {
        title: "Marketplace Credentials",
        id: "marketplace-credentials",
        link: "/settings/marketplace-credentials",
      },
      {
        title: "Help",
        id: "help",
        link: "/settings/help",
      },
    ],
  },
  {
    title: "Application Logs",
    icon: <i className="ki-outline ki-chart-simple-2 fs-1" />,
    id: "application-logs",
    subMenu: [
      {
        title: "Central Log",
        id: "central-log",
        link: "/application-logs/central-log",
      },
      {
        title: "System Error Log",
        id: "system-error-log",
        link: "/application-logs/system-error-log",
      },
    ],
  },
];

export const userMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-home-2 fs-1" />,
    id: "dashboard",
    link: "/",
    subMenu: [],
  },
  {
    title: "Inventory Order",
    icon: <i className="ki-outline ki-tablet-text-down fs-1" />,
    id: "inventory-order",
    link: "/inventory-order",
    subMenu: [],
  },
  {
    title: "Sources",
    icon: <i className="ki-outline ki-shop fs-1" />,
    id: "sources",
    link: "/sources",
    subMenu: [],
  },
  {
    title: "Inventory Items",
    icon: <i className="ki-outline ki-parcel fs-1" />,
    id: "inventory-items",
    link: "/inventory-items",
    subMenu: [],
  },
  {
    title: "Prep Batches",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    id: "prep-batches",
    link: "/prep-batches",
    subMenu: [],
  },
  {
    title: "Client Account",
    icon: <i className="ki-outline ki-profile-user fs-1" />,
    id: "client-account",
    // link: "/client-account",
    subMenu: [
      {
        title: "My Settings",
        id: "my-settings",
        link: "/my-settings",
      },
    ],
  },
  // {
  //   title: "My Settings",
  //   icon: <i className="ki-outline ki-gear fs-1" />,
  //   id: "my-settings",
  //   link: "/my-settings",
  //   subMenu: [],
  // },
  {
    title: "Help",
    icon: <i className="ki-outline ki-message-question  fs-1" />,
    id: "help",
    link: "/help",
    subMenu: [],
  },
  {
    title: "Application Logs",
    icon: <i className="ki-outline ki-chart-simple-2 fs-1" />,
    id: "application-logs",
    subMenu: [
      {
        title: "Central Log",
        id: "central-log",
        link: "/application-logs/central-log",
      },
      {
        title: "System Error Log",
        id: "system-error-log",
        link: "/application-logs/system-error-log",
      },
    ],
  },
];
