import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignUp from "../../modules/pages/sign-up";
import {
  loginAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  LoginResponse: state.Auth.LoginResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginAction,
      fakeActionAuth,
    },
    dispatch
  );

const SignUp_ = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUp_;
