import React, { useEffect } from "react";
import { initialCall } from "./config";
import { ConfigProvider, theme } from "antd";
import { createHashHistory } from "history";
import { Provider } from "react-redux";
import AppRoute from "./AppRoute";
import AppStyleWrapper from "./AppStyle";
import GlobalCommonContextProvider from "./commonContext";
import { store } from "./redux/configurations/store/configure-store";

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    var defaultThemeMode = localStorage.getItem("data-bs-theme") || "light";
    localStorage.setItem("data-bs-theme", defaultThemeMode);
    var themeMode;
    if (document.documentElement) {
      if (document.documentElement.hasAttribute("data-bs-theme-mode")) {
        themeMode = document.documentElement.getAttribute("data-bs-theme-mode");
      } else {
        if (localStorage.getItem("data-bs-theme") !== null) {
          themeMode = localStorage.getItem("data-bs-theme");
        } else {
          themeMode = defaultThemeMode;
        }
      }
      document.documentElement.setAttribute("data-bs-theme", themeMode);
    }
    if (
      !window.location.pathname.includes("/login") &&
      localStorage.getItem("userType") == 1
    ) {
      initialCall();
    }

    return () => {};
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm:
            localStorage.getItem("data-bs-theme") === "dark"
              ? darkAlgorithm
              : defaultAlgorithm,
          token: {
            fontFamily: "Inter, Helvetica, sans-serif",
          },
        }}
      >
        <GlobalCommonContextProvider>
          <Provider store={store}>
            <AppStyleWrapper />
            <AppRoute history={createHashHistory()} />
          </Provider>
        </GlobalCommonContextProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
