import axiosCall from "../../../configurations/network-services/axiosCall";

// --------------------------------------------------------------------------- New flow by Umangi from 21-09-2024
export const GetPrepBatchDetails = (id) => {
  const path = `prep-batch/${id}`;
  const responseType = "GET_PREP_BATCH_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCurrentStepData = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/batch-state-data`;
  const responseType = "GET_CURRENT_STEP_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateBatchStateData = (id, client_id, data) => {
  const path = `prep-batch/${id}/${client_id}/update-batch-state-data`;
  const responseType = "UPDATE_BATCH_STATE_DATA";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetPrepBatchDropdownData = () => {
  const path = `prep-batch/dropdown-data`;
  const responseType = "GET_PREP_BATCH_DROPDOWN_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const RemoveBatchInitialItems = (id, data) => {
  const path = `prep-batch/${id}/remove-items`;
  const responseType = "REMOVE_BATCH_INITIAL_ITEMS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SearchScannedItem = (item_uid, client_id) => {
  const path = `/prep-batch/search-item?item_uid=${item_uid}&client_id=${client_id}`;
  const responseType = "SEARCH_SCANNED_ITEM";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const AddItemsToBatch = (id, data) => {
  const path = `prep-batch/add-items-to-batch?batch_id=${id}`;
  const responseType = "ADD_ITEMS_TO_BATCH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetBatchSummaryItemsList = (id) => {
  const path = `prep-batch/${id}/items`;
  const responseType = "GET_BATCH_SUMMARY_ITEMS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ChangePrepBatchStatus = (id, data) => {
  const path = `prep-batch/${id}/update`;
  const responseType = "CHANGE_PREP_BATCH_STATUS";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetValidateBatchItems = (id) => {
  const path = `prep-batch/${id}/validate-batch-items`;
  const responseType = "GET_VALIDATE_BATCH_ITEMS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const RemoveItemsFromBatch = (id, data) => {
  const path = `prep-batch/${id}/remove-item-listing`;
  const responseType = "REMOVE_ITEM_FROM_BATCH";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CheckBatchListingFeedStatus = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/check-batch-listing-feed-status`;
  const responseType = "CHECK_BATCH_LISTING_FEED_STATUS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SubmitBatchItemsToAmazon = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/submit-batch-items-to-amazon`;
  const responseType = "SUBMIT_BATCH_ITEM_TO_AMAZON";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetBatchListingData = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/batch-listings`;
  const responseType = "GET_BATCH_LISTING_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetInboundShipments = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/get-inbound-shipments`;
  const responseType = "GET_INBOUND_SHIPMENT";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateInboundShipment = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/create-inbound-shipment`;
  const responseType = "CREATE_INBOUND_SHIPMENT";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateInboundShipmentPlan = (id, client_id, data) => {
  const path = `prep-batch/${id}/${client_id}/create-inbound-shipment-plan`;
  const responseType = "CREATE_INBOUND_SHIPMENT_PLAN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CancelShipment = (id, client_id, data) => {
  const path = `prep-batch/${id}/${client_id}/2/cancel-shipment`;
  const responseType = "CANCEL_SHIPMENT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetBatchContents = (id, client_id) => {
  const path = `prep-batch/${id}/${client_id}/get-batch-contents`;
  const responseType = "GET_BATCH_CONTETNS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionPrepBatch = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_PREP_BATCH" });
};
