import { SaveInventoryItemsWithoutOrder } from "./index.action";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_ITEM_LIST_SUCCESS":
    case "GET_ITEM_LIST_ERROR":
      return {
        ...state,
        GetItemListResponse: action.updatePayload,
      };
    case "GET_EXPECTED_ITEM_DETAILS_SUCCESS":
    case "GET_EXPECTED_ITEM_DETAILS_ERROR":
      return {
        ...state,
        GetExpectedItemsDetailsResponse: action.updatePayload,
      };
    case "GET_RECEIVE_ITEM_DROPDOWN_DATA_SUCCESS":
    case "GET_RECEIVE_ITEM_DROPDOWN_DATA_ERROR":
      return {
        ...state,
        GetReceiveItemDropdownDataResponse: action.updatePayload,
      };
    case "GET_RELATED_AMAZON_LIST_SUCCESS":
    case "GET_RELATED_AMAZON_LIST_ERROR":
      return {
        ...state,
        GetRelatedAmazonListResponse: action.updatePayload,
      };
    case "VALIDATE_RECEIVED_ITEMS_SUCCESS":
    case "VALIDATE_RECEIVED_ITEMS_ERROR":
      return {
        ...state,
        ValidateReceivedItemResponse: action.updatePayload,
      };
    case "FIND_ASIN_FROM_BARCODE_SUCCESS":
    case "FIND_ASIN_FROM_BARCODE_ERROR":
      return {
        ...state,
        FindAsinFromBarcodeResponse: action.updatePayload,
      };
    case "FIND_ADVANCE_ASIN_SEARCH_SUCCESS":
    case "FIND_ADVANCE_ASIN_SEARCH_ERROR":
      return {
        ...state,
        FindAdvanceAsinSearchResponse: action.updatePayload,
      };
    case "SAVE_INVENTORY_ITEMS_WITHOUT_ORDER_SUCCESS":
    case "SAVE_INVENTORY_ITEMS_WITHOUT_ORDER_ERROR":
      return {
        ...state,
        SaveInventoryItemsWithoutOrderResponse: action.updatePayload,
      };
    case "FAKE_ACTION_EXPECTED_ITEMS":
      return [];
    default:
      return state;
  }
};
