import { GetBatchContents } from "./prep-batch-action";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_PREP_BATCH_LIST_SUCCESS":
    case "GET_PREP_BATCH_LIST_ERROR":
      return {
        ...state,
        GetPrepBatchListResponse: action.updatePayload,
      };
    case "GET_PREP_BATCH_DROPDOWN_DATA_SUCCESS":
    case "GET_PREP_BATCH_DROPDOWN_DATA_ERROR":
      return {
        ...state,
        GetPrepBatchDropdownDataResponse: action.updatePayload,
      };
    case "GET_PREP_BATCH_DETAILS_SUCCESS":
    case "GET_PREP_BATCH_DETAILS_ERROR":
      return {
        ...state,
        GetPrepBatchDetailsResponse: action.updatePayload,
      };
    case "ADD_PREP_BATCH_SUCCESS":
    case "ADD_PREP_BATCH_ERROR":
      return {
        ...state,
        AddPrepBatchResponse: action.updatePayload,
      };
    case "UPDATE_PREP_BATCH_SUCCESS":
    case "UPDATE_PREP_BATCH_ERROR":
      return {
        ...state,
        UpdatePrepBatchResponse: action.updatePayload,
      };
    case "SEARCH_PRODUCT_ACTION_SUCCESS":
    case "SEARCH_PRODUCT_ACTION_ERROR":
      return {
        ...state,
        SearchProductResponse: action.updatePayload,
      };
    case "GET_BATCH_ITEMS_SUCCESS":
    case "GET_BATCH_ITEMS_ERROR":
      return {
        ...state,
        GetBatchItemsResponse: action.updatePayload,
      };
    case "ADD_ITEMS_TO_BATCH_SUCCESS":
    case "ADD_ITEMS_TO_BATCH_ERROR":
      return {
        ...state,
        AddItemsToBatchResponse: action.updatePayload,
      };
    case "CHANGE_BATCH_STATUS_SUCCESS":
    case "CHANGE_BATCH_STATUS_ERROR":
      return {
        ...state,
        ChangeBatchStatusResponse: action.updatePayload,
      };
    case "REMOVE_BATCH_ITEMS_SUCCESS":
    case "REMOVE_BATCH_ITEMS_ERROR":
      return {
        ...state,
        RemoveBatchItemsResponse: action.updatePayload,
      };
    case "GET_VALID_BATCH_ITEMS_SUCCESS":
    case "GET_VALID_BATCH_ITEMS_ERROR":
      return {
        ...state,
        GetValidBatchItemsResponse: action.updatePayload,
      };
    case "SUBMIT_BATCH_ITEM_TO_AMAZON_SUCCESS":
    case "SUBMIT_BATCH_ITEM_TO_AMAZON_ERROR":
      return {
        ...state,
        SubmitBatchItemsToAmazonResponse: action.updatePayload,
      };
    case "CREATE_INBOUND_SHIPMENT_SUCCESS":
    case "CREATE_INBOUND_SHIPMENT_ERROR":
      return {
        ...state,
        CreateInboundShipmentResponse: action.updatePayload,
      };
    case "GET_INBOUND_SHIPMENT_SUCCESS":
    case "GET_INBOUND_SHIPMENT_ERROR":
      return {
        ...state,
        GetInboundShipmentsResponse: action.updatePayload,
      };
    case "REMOVE_ITEM_LISTING_SUCCESS":
    case "REMOVE_ITEM_LISTING_ERROR":
      return {
        ...state,
        RemoveItemListingResponse: action.updatePayload,
      };
    case "REMOVE_BATCH_INITIAL_ITEMS_SUCCESS":
    case "REMOVE_BATCH_INITIAL_ITEMS_ERROR":
      return {
        ...state,
        RemoveBatchInitialItemsResponse: action.updatePayload,
      };
    case "CREATE_INBOUND_SHIPMENT_PLAN_SUCCESS":
    case "CREATE_INBOUND_SHIPMENT_PLAN_ERROR":
      return {
        ...state,
        CreateInboundShipmentPlanResponse: action.updatePayload,
      };

    // By Umangi
    case "GET_CURRENT_STEP_DATA_SUCCESS":
    case "GET_CURRENT_STEP_DATA_ERROR":
      return {
        ...state,
        GetCurrentStepDataResponse: action.updatePayload,
      };
    case "UPDATE_BATCH_STATE_DATA_SUCCESS":
    case "UPDATE_BATCH_STATE_DATA_ERROR":
      return {
        ...state,
        GetCurrentStepDataResponse: action.updatePayload,
      };
    case "SEARCH_SCANNED_ITEM_SUCCESS":
    case "SEARCH_SCANNED_ITEM_ERROR":
      return {
        ...state,
        SearchScannedItemResponse: action.updatePayload,
      };
    case "GET_BATCH_SUMMARY_ITEMS_SUCCESS":
    case "GET_BATCH_SUMMARY_ITEMS_ERROR":
      return {
        ...state,
        GetBatchSummaryItemResponse: action.updatePayload,
      };
    case "CHANGE_PREP_BATCH_STATUS_SUCCESS":
    case "CHANGE_PREP_BATCH_STATUS_ERROR":
      return {
        ...state,
        ChangePrepBatchStatusResponse: action.updatePayload,
      };
    case "GET_VALIDATE_BATCH_ITEMS_SUCCESS":
    case "GET_VALIDATE_BATCH_ITEMS_ERROR":
      return {
        ...state,
        GetValidateBatchItemsResponse: action.updatePayload,
      };
    case "REMOVE_ITEM_FROM_BATCH_SUCCESS":
    case "REMOVE_ITEM_FROM_BATCH_ERROR":
      return {
        ...state,
        RemoveItemsFromBatchResponse: action.updatePayload,
      };
    case "CANCEL_SHIPMENT_SUCCESS":
    case "CANCEL_SHIPMENT_ERROR":
      return {
        ...state,
        CancelShipmentResponse: action.updatePayload,
      };
    case "GET_BATCH_CONTETNS_SUCCESS":
    case "GET_BATCH_CONTETNS_ERROR":
      return {
        ...state,
        GetBatchContentsResponse: action.updatePayload,
      };
    case "CHECK_BATCH_LISTING_FEED_STATUS_SUCCESS":
    case "CHECK_BATCH_LISTING_FEED_STATUS_ERROR":
      return {
        ...state,
        CheckBatchListingFeedStatusResponse: action.updatePayload,
      };
    case "GET_BATCH_LISTING_DATA_SUCCESS":
    case "GET_BATCH_LISTING_DATA_ERROR":
      return {
        ...state,
        GetBatchListingDataResponse: action.updatePayload,
      };
    case "FAKE_ACTION_PREP_BATCH":
      return [];
    default:
      return state;
  }
};
