import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Gleap from "gleap";

const root = ReactDOM.createRoot(document.getElementById("root"));
if (!window.location.href.includes("login")) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
}

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
