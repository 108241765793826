import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import PageRouter from "../routes";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import Sidebar from "./layouts/sidebar";
import { handleStorageChange } from "../config";
// import { GlobalContext } from '../commonContext';

export default function () {
  // const contextVar = useContext(GlobalContext);
  const [sidebarHide, setSidebarHide] = useState(false);
  const [screenSize, setScreenSize] = useState(false);
  const [deviceType, setDeviceType] = useState("Desktop");

  const determineDeviceType = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      setDeviceType("Mobile");
    } else if (screenWidth >= 600 && screenWidth < 1200) {
      setDeviceType("Tablet");
    } else if (screenWidth >= 1200 && screenWidth < 1400) {
      setDeviceType("Laptop");
    } else if (screenWidth >= 1400 && screenWidth < 1600) {
      setDeviceType("largeLaptop");
    } else {
      setDeviceType("Desktop");
    }
  };

  function updateSize() {
    if (window.innerWidth < 992) {
      setScreenSize(true);
      setSidebarHide(true);
    } else {
      setScreenSize(screenSize);
      setSidebarHide(sidebarHide);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("storage", (e) => {
      handleStorageChange(e);
    });
    return () => {
      window.removeEventListener("storage", (e) => {
        handleStorageChange(e);
      });
    };
  }, []);

  //   useEffect(() => {
  //     determineDeviceType();
  //     window.addEventListener("resize", determineDeviceType);
  //     return () => {
  //       window.removeEventListener("resize", determineDeviceType);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     window.addEventListener("resize", updateSize);
  //     updateSize();
  //     return () => window.removeEventListener("resize", updateSize);
  //   }, []);

  const GetModules = () =>
    localStorage.getItem("userType") == 1
      ? "admin"
      : localStorage.getItem("userType") == 3
      ? "staff"
      : "user";

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      {/* begin::Page */}
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <Header
          hideSidebar={() => setSidebarHide(!sidebarHide)}
          hide={sidebarHide}
        />
        <div
          className={`app-wrapper flex-column flex-row-fluid ${
            sidebarHide ? "open" : "close"
          }`}
          id="kt_app_wrapper"
        >
          <Sidebar
            hide={sidebarHide}
            screenSize={screenSize}
            deviceType={deviceType}
          />
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <PageRouter authStatus={GetModules()} />
          </div>
          <Footer />
        </div>
      </div>
      {!sidebarHide && screenSize && (
        <div
          onClick={() => setSidebarHide(!sidebarHide)}
          style={{ zIndex: 105 }}
          className="drawer-overlay"
        />
      )}
      {/* end::Page */}
    </div>
  );
}
